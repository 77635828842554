import { useContext, useEffect, useState } from 'react';

import {
	FXSLeftColLayout,
	FXSRightColLayout,
	TwoColsMainLayout
} from 'components/Layouts';
import { RecordingsHeader } from 'components/RecordingsHeader';
import { FXSDividerWide } from 'components/shared';
import { AuthorsContext } from 'contexts/authorsContext';
import { ConfigContext } from 'contexts/configContext';
import { PushContext } from 'contexts/pushContext';
import { useTranslationsContext } from 'contexts/translationsContext';
import { UserContext } from 'contexts/userContext';
import { usePostPush } from 'data/usePostPush';
import { AuthorsExperts } from 'fxs-experts-list';
import { Recordings } from 'fxs-recordings';
import useSound from 'use-sound';
import { isLessThan } from 'utils/useWindowWidth';

export default function RecordingsPage() {
	const { t } = useTranslationsContext();

	const { user } = useContext(UserContext);
	const connected = useContext(PushContext);
	const { authorsResponse } = useContext(AuthorsContext);
	const [soundOn, setSoundOn] = useState(user?.isPremium);
	const [, setPushCount] = useState(null);
	const { config } = useContext(ConfigContext);

	const [play] = useSound(config.Variables.NotificationSoundUrl);

	const count = usePostPush(
		connected,
		user?.isPremium,
		'VideoPushNotification'
	);

	useEffect(() => {
		setPushCount(count);
		if (soundOn) play();
	}, [count]);
	const isLessThan1280 = isLessThan(1280);
	const isLessThan833 = isLessThan(833);

	return (
		<TwoColsMainLayout>
			<FXSLeftColLayout
				isLessThan1280={isLessThan1280}
				isLessThan833={isLessThan833}
			>
				<RecordingsHeader>{t('latestVideos')}</RecordingsHeader>
				<Recordings
					appId={config.Variables.AlgoliaAppId}
					apiKey={config.Variables.AlgoliaApiKey}
					indexName={config.Variables.AlgoliaIndex}
					hitsPerPage={25}
					culture={config.Variables.Culture}
					t={t}
					onToggleNotificationsSound={(activated) => {
						setSoundOn(activated);
					}}
				/>
			</FXSLeftColLayout>
			<FXSRightColLayout
				isLessThan1280={isLessThan1280}
				isLessThan833={isLessThan833}
				removePaddings={false}
			>
				{isLessThan1280 && <FXSDividerWide />}
				{authorsResponse && (
					<AuthorsExperts
						data={authorsResponse.data}
						isFetching={
							!authorsResponse.data && !authorsResponse.error
						}
						error={!authorsResponse.data}
						t={t}
						siteUrl={config.Variables.FxsSite}
					/>
				)}
			</FXSRightColLayout>
		</TwoColsMainLayout>
	);
}
