import { useContext } from 'react';

import {
	FXSLeftColLayout,
	FXSRightColLayout,
	TwoColsMainLayout
} from 'components/Layouts';
import { FXSDividerWide } from 'components/shared';
import { AuthorsContext } from 'contexts/authorsContext';
import { ConfigContext } from 'contexts/configContext';
import { useTranslationsContext } from 'contexts/translationsContext';
import { EventList } from 'fxs-event-list';
import { AuthorsExperts } from 'fxs-experts-list';
import { FXS_hd_small_med } from 'styles/typography';
import { isLessThan } from 'utils/useWindowWidth';

export default function Webinars() {
	const { t } = useTranslationsContext();
	const isLessThan1280 = isLessThan(1280);
	const isLessThan833 = isLessThan(833);
	const { authorsResponse } = useContext(AuthorsContext);
	const { config } = useContext(ConfigContext);

	return (
		<TwoColsMainLayout>
			<FXSLeftColLayout
				isLessThan1280={isLessThan1280}
				isLessThan833={isLessThan833}
			>
				<FXS_hd_small_med style={{ marginBottom: '1rem' }}>
					{t('nextWebinarsTitle')}
				</FXS_hd_small_med>
				<EventList
					appId={config.Variables.AlgoliaAppId}
					apiKey={config.Variables.AlgoliaApiKey}
					indexName={config.Variables.AlgoliaIndex}
					take={40}
					culture={config.Variables.Culture}
					t={t}
				/>
			</FXSLeftColLayout>
			<FXSRightColLayout
				isLessThan1280={isLessThan1280}
				isLessThan833={isLessThan833}
				removePaddings={false}
			>
				{isLessThan1280 && <FXSDividerWide />}
				{authorsResponse && (
					<AuthorsExperts
						data={authorsResponse.data}
						isFetching={
							!authorsResponse.data && !authorsResponse.error
						}
						error={!authorsResponse.data}
						t={t}
						siteUrl={config.Variables.FxsSite}
					/>
				)}
			</FXSRightColLayout>
		</TwoColsMainLayout>
	);
}
