import styled from 'styled-components';
import { COLORS } from 'styles/colors';

interface LayoutProps {
	isLessThan833: boolean;
	isLessThan1280?: boolean;
}

export const FXSTwoCols = styled.main<LayoutProps>`
	grid-area: main;
	display: flex;
	flex-wrap: wrap;
	height: ${(props) =>
		props.isLessThan833 ? 'calc( 100vh - 55px )' : 'calc( 100vh - 66px )'};
	padding: ${(props) => (props.isLessThan833 ? '0' : '25px')};
	background: ${COLORS.grey300};
	scrollbar-width: none;
	overflow-y: auto;
	overscroll-behavior: contain;
	-ms-overflow-style: none;
	&::-webkit-scrollbar {
		display: none;
	}
	@media (min-width: 1540px) {
		width: 1280px;
		margin: 0 auto;
		overflow-y: hidden;
	}
`;
export const FXSFullWidthCol = styled.main<LayoutProps>`
	grid-area: main;
	height: 100vh;
	max-height: ${(props) =>
		props.isLessThan833 ? 'calc( 100vh - 55px )' : 'calc( 100vh - 66px )'};
	padding: ${(props) => (props.isLessThan833 ? '0' : '25px')};
	background: ${COLORS.grey300};
	scrollbar-width: none;
	overflow-y: auto;
	-ms-overflow-style: none;
	&::-webkit-scrollbar {
		display: none;
	}
	@media (min-width: 1540px) {
		width: 100%;
		max-width: 1280px;
		margin: 0 auto;
	}
`;

export const FXSFullWidth = styled.main<LayoutProps>`
	grid-area: main;
	height: 100vh;
	max-height: ${(props) =>
		props.isLessThan833 ? 'calc( 100vh - 55px )' : 'calc( 100vh - 66px )'};
	background: white;
	display: grid;
	scrollbar-width: none;
	-ms-overflow-style: none;
	&::-webkit-scrollbar {
		display: none;
	}
`;
